import React from 'react';
import './Transport.style.css';
import StatusCell from '../StatusCell/StatusCell';

const Transport = ({item}) => {
    return (
        <tr className="Transport">
            <td>{item.cRoute}</td>
            <td>{item.dDateArrival}</td>
            <td>{item.cJurperson}</td>
            <td>{item.cAuto_Route}</td>
            <StatusCell status={item.cRouteStat}></StatusCell>
            <td>{item.nGate_Route}</td>
            <td>{item.dDateBeginShip}</td>
        </tr>
    );
}

export default Transport;