import React from "react";
import Transport from "../Transport/Transport";
import './TransportList.style.css';

const TransportList = ( { items } ) => {
    return (
        <table className="TransportList">
            <thead>
                <tr>
                    <th className="defaultCol">Пропуск</th>
                    <th className="datetimeCol">Время въезда</th>
                    <th>Клиент</th>
                    <th className="defaultCol">Машина</th>
                    <th className="statusCol">Статус</th>
                    <th className="defaultCol">Ворота</th>
                    <th className="datetimeCol">Время ожидания</th>
                </tr>
            </thead>
            <tbody>
                {
                    items.map((item) => {
                        return (
                            <Transport item={item} key={item.cRoute} />
                        );
                    })
                }
            </tbody>
        </table>
    );
}

export default TransportList;