import React, { useEffect, useState } from 'react';
import './Clock.style.css';

const daysOfWeek = ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"];
const months = ["Января", "Февраля", "Марта", "Апреля", "Мая", "Июня", "Июля", "Августа", "Сентября", "Октября", "Ноября", "Декабря"];

const Clock = (props) => {
    const [year, setYear] = useState(0);
    const [month, setMonth] = useState('');
    const [day, setDay] = useState(0);
    const [dayOfWeek, setDayOfWeek] = useState('');
    const [time, setTime] = useState(0);

    useEffect(() => {
        const update = () => {
            const date = new Date();
            const year = date.getFullYear();
            const month = date.getMonth();
            const day = date.getDate();
            setYear(year);
            setMonth(months[month]);
            setDay(day);
            setDayOfWeek(daysOfWeek[date.getDay()]);
            setTime(date.toLocaleTimeString());
        }
        update();

        const interval = setInterval(() => {
            update();
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="Clock">
            <strong>{dayOfWeek} {day} {month} {year} г. {time}</strong>
        </div>
    );
}

export default Clock;