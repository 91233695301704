//import config from "./config.json"
import Clock from "./components/Clock/Clock";
import TransportList from "./components/TransportList/TransportList";
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';

function App() {

    const apiURL = useRef(window.location.origin + "/api/transport");
    const requestParams = useRef({
        ItemsCountPage: (window.ROWS_PER_PAGE || 10) <= 1 ? 5 : (window.ROWS_PER_PAGE || 10),
        Page: 1
    });

    const [pageData, setPageData] = useState({
        transportList: [], //Список маршрутов на текущей странице
        totalTransport: 0, //Всего маршрутов
        page: 1, //Текущая страница
        totalPages: 1 //Всего страниц
    });
    useEffect(() => {
        const getTransport = () => {
            axios.get(apiURL.current, { params: requestParams.current })
                .then((resp) => {
                    let currentPage = resp.data.Page;
                    let nextPage = resp.data.Pages > currentPage ? currentPage + 1 : 1;

                    requestParams.current = {
                        ItemsCountPage: window.ROWS_PER_PAGE || 10,
                        Page: nextPage
                    }

                    setPageData({
                        transportList: resp.data.GetRoute,
                        totalTransport: resp.data.ItemsCount,
                        page: resp.data.Page,
                        totalPages: resp.data.Pages
                    });
                })
                .catch(error => {
                    setPageData({
                        transportList: [],
                        totalTransport: 0,
                        page: 1,
                        totalPages: 1
                    });
                });
        }
        getTransport();
        const interval = setInterval(() => {
            getTransport();
        }, window.PAGE_WAITING || 10000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="App">
            <div className="mainContent">
                <Clock />
                <TransportList items={pageData.transportList || []} />
            </div>
            <div className="summaryInfo">
                <p><b>Всего в очереди: {pageData.totalTransport || 0}</b></p>
                <p><b>Стр. {pageData.page || 1} из {pageData.totalPages || 1}</b></p>
            </div>
        </div>
    );
}
export default App;