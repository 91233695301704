import React from "react";
import './StatusCell.style.css';

const StatusCell = (actionStatus) => {
    let imgPath = process.env.PUBLIC_URL + '/img';
    switch (actionStatus.status.toLowerCase()) {
        case 'новый': {
            imgPath = imgPath + '/truck-new.svg';
            break;
        }
        case 'в очереди': {
            imgPath = imgPath + '/truck-wait.svg';
            break;
        }
        case 'приглашается': {
            imgPath = imgPath + '/truck-invited.svg';
            break;
        }
        case 'в работе': {
            imgPath = imgPath + '/loader.svg';
            break;
        }
        case 'отгружен': {
            imgPath = imgPath + '/truck-loaded.svg';
            break;
        }
        case 'выехал': {
            imgPath = imgPath + '/truck-left.svg';
            break;
        }
        default: {
            imgPath = imgPath + '/truck-new.svg';
            break;
        }
    }

    return (
        <td className="statusCell">
            <div className="cell">
                <img src={imgPath} alt="" height="30" width="30" className="statusIcon"/>
                {actionStatus.status}
            </div>
        </td>
    );
}

export default StatusCell;